import classNames from "classnames";
import { ComponentProps, FunctionComponent } from "react";

interface SectionTitleProps extends ComponentProps<"div"> {}

const SectionTitle: FunctionComponent<SectionTitleProps> = ({ className, ...props }) => {
  return <div className={classNames("border-b-2 border-b-theme-primary text-[24px] font-bold pb-2", className)} {...props}></div>;
};

export default SectionTitle;
