import { ROUTES } from "constants/routes";
import dayjs from "dayjs";
import { generatePath } from "react-router-dom";
import Link from "./link";

export type NewsCardProps = {
  title: string;
  publishAt: string;
  id: number;
  shortSummary: string;
};

const NewsCard = ({ title, shortSummary, publishAt, id }: NewsCardProps) => {
  return (
    <Link
      className="bg-white hover:bg-theme-background border rounded p-5 space-y-3 cursor-pointer"
      to={generatePath(ROUTES.newsArticleView, {
        newsArticleId: id,
      })}
    >
      <div className="line-clamp-2 text-[20px]">{title}</div>
      <div className="flex">
        <div className="bg-theme-border leading-6 rounded-full px-3 text-xs">
          {dayjs(publishAt).format("YYYY-MM-DD")}
        </div>
      </div>
      <div className="line-clamp-3" dangerouslySetInnerHTML={{ __html: shortSummary }} />
    </Link>
  );
};

export default NewsCard;
