import Container from "components/container";
import Layout from "components/layout";
import { useGetNewsArticle } from "hooks/api/use-get-news-article";
import { useRequiredParams } from "hooks/use-required-params";
import SectionTitle from "../home/components/section-title";
import { RichTextContainer } from "components/rich-text-container";
import useFormattedDate from "hooks/use-formatted-date";
import FileViewButton from "components/file-view-button";
import { useDictionary } from "hooks/use-dictionary";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { ROUTES } from "constants/routes";

export const NewsArticlePage = () => {
  const { newsArticleId } = useRequiredParams<{ newsArticleId: string }>();
  const { data: newsArticle, isLoading, isError } = useGetNewsArticle(+newsArticleId);
  const formattedDate = useFormattedDate(newsArticle?.publishAt);
  const dict = useDictionary();

  if (isLoading || isError || !newsArticle) {
    return null;
  }

  return (
    <Layout>
      <div className="space-y-8 py-12">
        <Container className="bg-theme-background" childClassName="space-y-4">
          <Breadcrumbs>
            <Link to={ROUTES.home}>{dict.common.breadcrumbsRoot}</Link>
            <Link to={ROUTES.newsList}>{dict.news.news}</Link>
            <div>{newsArticle?.title}</div>
          </Breadcrumbs>
          <RichTextContainer>
            <div className="space-y-5">
              <SectionTitle>{newsArticle?.title}</SectionTitle>
              <div className="bg-white border rounded p-5 space-y-5">
                <div className="bg-theme-border leading-6 rounded-full px-3 text-xs w-fit">{formattedDate}</div>
                <div className="space-y-2">
                  <div className="font-bold mb-2">{dict.news.content}</div>
                  <div dangerouslySetInnerHTML={{ __html: newsArticle.content }} />
                </div>
                {newsArticle.files && (
                  <div className="space-y-2">
                    <div className="font-bold mb-2">{dict.news.documents}</div>

                    <div className="space-y-3">
                      {newsArticle.files.map((file) => (
                        <FileViewButton file={file} key={file.fileName} />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </RichTextContainer>
        </Container>
      </div>
    </Layout>
  );
};
