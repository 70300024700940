import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useLoading } from "providers/loading";
import { AxiosResponse } from "axios";

interface useGetOneOptions<T> {
  queryFn: () => Promise<AxiosResponse<T, any> | null>;
  keys: any[];
  enabled?: boolean;
}

export const useGetOne = <T>({ queryFn, keys, enabled = true }: useGetOneOptions<T>) => {
  const { data, isPending, isPlaceholderData } = useQuery<T | null>({
    placeholderData: keepPreviousData,
    queryKey: keys,
    queryFn: async () => {
      const res = await queryFn();
      if (!res) return null;
      const { status, data } = res;
      return status >= 200 && status < 300 ? (data as T) : null;
    },
    enabled,
  });

  let isLoading = isPending || isPlaceholderData;

  if (!enabled) {
    isLoading = false;
  }

  const { addLoader, removeLoader } = useLoading();

  useEffect(() => {
    if (isLoading) {
      const loaderId = addLoader();
      return () => removeLoader(loaderId);
    }
  }, [isLoading, addLoader, removeLoader]);

  return { data, isLoading };
};
