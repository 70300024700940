import { ClassifierValue } from "types/classifier";
import { ProgramDecisionStatus } from "./program";

export enum ProgramStatusType {
  HYGIENE_INSTITUTION_DECISION = "HYGIENE_INSTITUTION_DECISION",
  EVALUATOR_DECISION = "EVALUATOR_DECISION",
  MAIN_DECISION = "MAIN_DECISION",
}

export interface ProgramEvaluationStatus {
  id: number;
  text: string;
  status: ProgramDecisionStatus;
  files: { url: string; id: number; file: null; fileName: string; fileSize: number }[];
  evaluator: { firstName: string; lastName: string; id?: number } | null;
  type: ProgramStatusType;
  length: number;
  programDecisionReasonClassifiers: ClassifierValue[];
}
