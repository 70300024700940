import { ClassifierValue } from "types/classifier";
import { FileRecord } from "./file-record";
import {
  FormMandatoryTopicClassifier,
  FormMandatoryTopicClassifierDraft,
  FormProgramBase,
  MandatoryTopicClassifier,
  ProgramBase,
} from "./program";
import { LearningProgram, LearningProgramDraft } from "./learning-program";

export interface FormDevelopmentCompetencyGroupClassifier {
  developmentCompetencyGroupClassifierId: string;
  developmentCompetencyGroupDurationHours: number;
}
export interface DevelopmentCompetencyGroupClassifier {
  id: number;
  classifier: ClassifierValue;
  classiffier: ClassifierValue;
  duration: number;
}

export interface DevelopmentDraftCompetencyGroupClassifier {
  developmentCompetencyGroupClassifierId: ClassifierValue;
  developmentCompetencyGroupDurationHours: number;
}

export interface ProgramDescriptionFile {
  url: string;
  id: number;
  fileName: string;
  fileSize: number;
}

export interface DevelopmentProgram extends ProgramBase {
  programGoal: string;
  theoreticalDistribution: number;
  practicalDistribution: number;
  programFormClassifier: ClassifierValue | null;
  evaluationFormClassifier: ClassifierValue;
  programDescriptionFiles: ProgramDescriptionFile[];
  developmentProgramMethodOfExecution: ClassifierValue;
  developmentCompetencyGroupClassifiers: DevelopmentCompetencyGroupClassifier[];
  files: FileRecord[];
  duration: number;
}

export interface DevelopmentProgramDraft {
  duration: Number;
  descriptionSummary: string;
  developmentCompetencyGroups: DevelopmentDraftCompetencyGroupClassifier[];
  evaluationFormClassifierId: ClassifierValue;
  files: FileRecord[];
  formClassifierId: ClassifierValue;
  listenerCountFrom: number;
  listenerCountTo: number;
  location: string;
  mandatoryTopics: FormMandatoryTopicClassifierDraft[];
  methodOfExecution: ClassifierValue;
  name: string;
  practicalDevelopmentDurationHours: number;
  programGoals: string;
  targetAudienceClassifierIds: ClassifierValue[] | null;
  targetedProgramClassifierIds: ClassifierValue[] | null;
  theoreticalDevelopmentDurationHours: number;
  typeClassifierId: ClassifierValue;
}

export interface FormDevelopmentProgram extends FormProgramBase {
  location?: string;
  developmentCompetencyGroups?: FormDevelopmentCompetencyGroupClassifier[];
  methodOfExecution?: string;
  evaluationFormClassifierId?: string;
  formClassifierId?: string;
  files?: (File | FileRecord)[];
  programGoals?: string;
  theoreticalDevelopmentDurationHours?: number;
  practicalDevelopmentDurationHours?: number;
  targetAudienceClassifierIds?: string[];
}

export interface ProgramDraft {
  content: DevelopmentProgramDraft | LearningProgramDraft;
  creator: number;
  id: string;
  type: string;
}

export enum DevelopmentCompetencyGroup {
  CLINICAL_SPECIALTIES = "klinikinės (specialybinės)",
  GENERAL_CLINICAL_SPECIALTIES = "bendrosios klinikinės (specialybinės) ",
  GENERAL_SPECIALTIES = "bendrosios (ne klinikinės (ne specialybinės))",
}
