import classNames from "classnames";
import { ComponentProps, FunctionComponent } from "react";

interface FormGridProps extends ComponentProps<"div"> {}

const FormGrid: FunctionComponent<FormGridProps> = ({ className, ...props }) => {
  return <div className={classNames("grid @[700px]:grid-cols-2 gap-7", className)} {...props}></div>;
};

export default FormGrid;
