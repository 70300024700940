import classNames from "classnames";
import { ComponentProps, FunctionComponent } from "react";

interface TagBaseProps extends ComponentProps<"div"> {}

const TagBase: FunctionComponent<TagBaseProps> = ({ className, ...props }) => {
  return (
    <div
      className={classNames("bg-theme-gray100 rounded-full text-xs flex items-center px-3 h-7 gap-1.5 text-nowrap", className)}
      {...props}
    ></div>
  );
};

export default TagBase;
