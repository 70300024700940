import apiClient from "api/api-client";
import { QUERY_KEYS } from "constants/query-keys";
import { useList } from "hooks/use-list";
import { ApiListResponse } from "types/api";
import { Event } from "types/event";

export const usePublicEvents = (limitNumber?: number) => {
  return useList({
    keys: [QUERY_KEYS.events],
    queryFn: async ({ offset, limit, filters }) => {
      return apiClient.get<ApiListResponse<Event>>("/public/events", {
        params: { offset, limit: limitNumber ?? limit, ...filters },
      });
    },
    limit: limitNumber,
  });
};
