import { QUERY_KEYS } from "constants/query-keys";
import apiClient from "api/api-client";
import { ApiListResponse } from "types/api";
import { Notification } from "types/notification";
import { useList } from "hooks/use-list";

const useNotifications = (limitNumber?: number, enabled?: boolean) => {
  return useList<Notification>({
    keys: [QUERY_KEYS.notifications, limitNumber],
    queryFn: async ({ offset, limit, filters }) => {
      const response = await apiClient.get<ApiListResponse<Notification>>(`/admin/notifications`, {
        params: { offset, limit: limitNumber ?? limit, ...filters },
      });
      return response;
    },
    enabled: enabled ?? true,
  });
};

export default useNotifications;
