import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import classNames from "classnames";
import { Control, Controller, FieldError, FieldValues, Path } from "react-hook-form";
import { useId } from "react-id-generator";
import { useDictionary } from "hooks/use-dictionary";
import { TextField } from "@mui/material";

type OptionType = {
  value: number;
  label: string | null;
  textRequired: boolean;
};
type SelectedOption = { selectedOption: number };

interface SurveyRadioGroupProps<T extends FieldValues> {
  options: OptionType[];
  control: Control<T>;
  name: Path<T>;
  label: string;
  disabled?: boolean;
}

const SurveyRadioGroup = <T extends FieldValues>({
  options,
  control,
  name,
  label,
  disabled = false,
  ...rest
}: SurveyRadioGroupProps<T>) => {
  const ids = useId(options.length);
  const dict = useDictionary();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange: fieldOnChange }, fieldState: { error } }) => {
        const getTextFieldValue = (optionValue: number) => {
          const option = value?.find((item: SelectedOption) => item.selectedOption === optionValue);
          return option?.text || "";
        };

        const canEnterTextFieldValue = (optionValue: number) => {
          const option = value?.find((item: SelectedOption) => item.selectedOption === optionValue);
          return !!option;
        };

        const getTextFieldErrorMessage = (fieldErrors: FieldError | undefined): string | undefined => {
          if (!fieldErrors) return undefined;

          if (Array.isArray(fieldErrors)) {
            return fieldErrors[fieldErrors.length - 1]?.text?.message;
          }
        };

        const handleChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, option: OptionType) => {
          const targetValue = (event.target as HTMLInputElement).value;
          if (
            Array.isArray(value) &&
            value.some((item: SelectedOption) => item.selectedOption === parseInt(targetValue))
          ) {
            fieldOnChange(undefined);
          } else {
            const newValue = [
              {
                selectedOption: parseInt(targetValue),
                textRequired: option.textRequired,
              },
            ];
            fieldOnChange(newValue);
          }
        };
        return (
          <FormControl error={!!error}>
            <FormLabel
              classes={{
                root: classNames("!mb-2", disabled || !!error ? "!text-theme-text" : ""),
              }}
            >
              {label}
            </FormLabel>
            <RadioGroup
              color="primary"
              value={value?.[0]?.selectedOption ?? ""} // Ensure value is not undefined
              {...rest}
            >
              {options.map((option, i) => (
                <FormControlLabel
                  key={`radio-${ids[i]}-${i}`}
                  value={option.value}
                  control={
                    <Radio
                      disabled={disabled}
                      onClick={(e) => handleChange(e, option)}
                      inputProps={{
                        "aria-label": option.textRequired ? dict.common.ariaLabels.freeFormAnswer : option.label ?? "",
                      }}
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      width: "100%",
                      "@media (min-width: 640px)": {
                        width: "50%",
                      },
                    },
                  }}
                  label={
                    option.textRequired ? (
                      <TextField
                        fullWidth
                        disabled={disabled || !canEnterTextFieldValue(option.value)}
                        placeholder={dict.surveys.freeFormAnswer}
                        error={!!getTextFieldErrorMessage(error)}
                        helperText={getTextFieldErrorMessage(error)}
                        value={getTextFieldValue(option.value)}
                        onChange={(e) => {
                          fieldOnChange([
                            {
                              selectedOption: option.value,
                              text: e.target.value,
                              textRequired: option.textRequired,
                            },
                          ]);
                        }}
                        inputProps={{
                          sx: {
                            "&::placeholder": {
                              fontWeight: "500",
                              color: "#000000",
                            },
                          },
                        }}
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#4B5563",
                          },
                        }}
                      />
                    ) : (
                      option.label
                    )
                  }
                />
              ))}
            </RadioGroup>
            {error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

export default SurveyRadioGroup;
