import {
  DevelopmentProgram,
  DevelopmentProgramDraft,
  LearningProgram,
  LearningProgramDraft,
  ProgramType,
} from "types/program";

export const isDevelopmentType = (
  program: DevelopmentProgram | LearningProgram | DevelopmentProgramDraft | LearningProgramDraft
): program is DevelopmentProgram => {
  return (program as DevelopmentProgram).type === ProgramType.DEVELOPMENT_TYPE;
};

export const isLearningType = (
  program: DevelopmentProgram | LearningProgram | DevelopmentProgramDraft | LearningProgramDraft
): program is LearningProgram => {
  return (program as LearningProgram).type === ProgramType.LEARNING_TYPE;
};

export const isLearningProgramDraft = (
  isDraft: boolean,
  program: LearningProgram | LearningProgramDraft | DevelopmentProgram | DevelopmentProgramDraft
): program is LearningProgramDraft => {
  if (isDraft) {
    return true;
  }
  return false;
};

export const isDevelopmentProgramDraft = (
  isDraft: boolean,
  program: DevelopmentProgram | DevelopmentProgramDraft | LearningProgram | LearningProgramDraft
): program is DevelopmentProgramDraft => {
  if (isDraft) {
    return true;
  }
  return false;
};
