import { useDictionary } from "hooks/use-dictionary";
import Layout from "components/layout";
import HeroImage from "assets/home/hero.png";
import Container from "components/container";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const ExternalEvaluationFormAnsweredPage = () => {
  const dict = useDictionary();

  const location = useLocation();
  const state = location.state as { isOpenedAfterSubmit: boolean };

  const [isOpenedAfterSubmit] = useState(state?.isOpenedAfterSubmit || false);

  return (
    <Layout>
      <div className="bg-center bg-cover" style={{ backgroundImage: `url(${HeroImage})` }}>
        <Container className="bg-theme-text/55">
          <div className="justify-center flex py-20">
            <div className="bg-white rounded py-16 px-6 w-full">
              <div className="space-y-6 text-center">
                <div className="text-3xl text-center leading-tight font-semibold">
                  {dict.questionnaires.externalEvaluationForm}
                </div>
                <div className="h-0.5 bg-theme-primary" />

                <div className="text-md sm:text-3xl text-center leading-tight font-semibold space-y-6">
                  {isOpenedAfterSubmit &&
                    dict.messages.externalEvaluationFormAnswerSaveSuccess
                      .split("<br/>")
                      .map((part, index) => <div key={index}>{part}</div>)}
                  {!isOpenedAfterSubmit && dict.messages.externalEvaluationFormAlreadyAnswered}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default ExternalEvaluationFormAnsweredPage;
