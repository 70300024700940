import apiClient from "api/api-client";
import { QUERY_KEYS } from "constants/query-keys";
import { useList } from "hooks/use-list";
import { ApiListResponse } from "types/api";
import { OrganizerInstitution } from "types/institution/organizer";

export const usePublicOrganizerInstitutions = () => {
  return useList({
    keys: [QUERY_KEYS.publicOrganizerInstitutions],
    queryFn: async ({ offset, limit, filters }) => {
      return apiClient.get<ApiListResponse<OrganizerInstitution>>("/public/organizer-institutions", {
        params: { offset, limit, ...filters },
      });
    },
  });
};
