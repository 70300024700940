import { QUERY_KEYS } from "constants/query-keys";
import apiClient from "api/api-client";
import { EvaluationQuestionnaireUserRecordStatusEnum } from "types/questionnaire";
import { useGetOne } from "hooks/use-get-one";

type ExternalEvaluationFormStatus = {
  status: EvaluationQuestionnaireUserRecordStatusEnum;
};

export const useGetExternalEvaluationFormStatus = (publicId: string) => {
  return useGetOne<ExternalEvaluationFormStatus>({
    keys: [QUERY_KEYS.externalEvaluationFormStatus, publicId],
    queryFn: async () =>
      apiClient.get<ExternalEvaluationFormStatus>(
        `/public/evaluation-questionnaires/${publicId}/status`
      ),
  });
};
