import { ComponentProps, FunctionComponent, useMemo } from "react";
import { Event } from "types/event";
import { useDictionary } from "hooks/use-dictionary";
import { interpolate } from "utils/interpolate";
import { DevelopmentProgram, LearningProgram, ProgramType } from "types/program";
import useFormattedDate from "hooks/use-formatted-date";
import { COLORS } from "constants/colors";
import { Typography } from "@mui/material";
import DevelopmentEventTags from "pages/organizer-admin/events/components/tags/development";
import LearningEventTags from "pages/organizer-admin/events/components/tags/learning";

interface EventCardProps extends ComponentProps<"div"> {
  event: Event;
}

const EventCard: FunctionComponent<EventCardProps> = ({ event, className, ...props }) => {
  const dict = useDictionary();

  const interpolatedDuration = useMemo(() => {
    const durationValue =
      event.program.duration === null
        ? (event.program as DevelopmentProgram).developmentCompetencyGroupClassifiers.reduce(
            (total, group) => total + group.duration,
            0
          )
        : event.program.duration;

    return interpolate(dict.organizerAdmin.events.durationValue, {
      value: `${durationValue}`,
    });
  }, [event.program, dict.organizerAdmin.events.durationValue]);
  const eventStartDate = useFormattedDate(event.startDate);
  const eventEndDate = useFormattedDate(event.endDate);

  return (
    <div className="bg-white border rounded">
      <div className="flex flex-col p-6 gap-6">
        <div className="flex">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-2">
              <Typography variant="body2" color={COLORS.gray300}>
                {eventStartDate} - {eventEndDate}
              </Typography>
              <Typography variant="h2">{event.name}</Typography>
            </div>
            <div className="flex flex-row gap-6">
              <div className="border-l-4 border-theme-primary pl-2 basis-1/2 h-fit">
                <div className="text-theme-gray300">{dict.organizerAdmin.events.type}</div>
                <div>
                  {" "}
                  {event.program?.type === ProgramType.DEVELOPMENT_TYPE
                    ? dict.organizerAdmin.events.eventByDevelopmentProgram
                    : dict.organizerAdmin.events.eventByLearningProgram}
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-4 ml-auto mr-0">
            <div className="space-y-2">
              <Typography variant="body2" color={COLORS.gray300}>
                {dict.organizerAdmin.events.eventDuration}
              </Typography>
              <Typography variant="h2">{interpolatedDuration}</Typography>
            </div>
            <div className="space-y-2">
              <Typography variant="body2" color={COLORS.gray300}>
                {dict.organizerAdmin.events.eventPrice}
              </Typography>
              <Typography variant="h2">
                {" "}
                {interpolate(dict.organizerAdmin.events.priceValue, {
                  value: event.price,
                })}
              </Typography>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-auto-[2fr,1fr] gap-x-4 items-start relative">
          <div className="relative flex flex-col-reverse sm:flex-row">
            <div className="inline-flex flex-wrap">
              {event.program.type === ProgramType.DEVELOPMENT_TYPE && (
                <DevelopmentEventTags event={event} program={event.program as DevelopmentProgram} />
              )}
              {event.program.type !== ProgramType.DEVELOPMENT_TYPE && (
                <LearningEventTags event={event} program={event.program as LearningProgram} />
              )}
            </div>
            <div className="flex-grow"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
