import IconButton from "components/icon-button";
import { ComponentProps, FunctionComponent, useRef, useState } from "react";
import { Portal } from "react-portal";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useId } from "react-id-generator";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { useClickAway } from "react-use";
import { DashboardLink } from "components/dashboard/layout";
import { useAuth } from "providers/auth";
import { useDictionary } from "hooks/use-dictionary";
import { Button } from "@mui/material";
import { ROUTES } from "constants/routes";

interface MenuButtonProps extends ComponentProps<typeof IconButton> {
  links: { title: string; href: string }[];
  dashboardLinks?: DashboardLink[];
}

const MenuButton: FunctionComponent<MenuButtonProps> = ({ links, dashboardLinks, ...props }) => {
  const ids = useId(links.length);
  const [open, setOpen] = useState(false);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const dict = useDictionary();
  const ref = useRef<HTMLDivElement>(null);
  useClickAway(ref, () => {
    setOpen(false);
  });

  return (
    <>
      <IconButton {...props} onClick={() => setOpen((prev) => !prev)}>
        <MenuIcon className="text-theme-primary" />
      </IconButton>
      <Portal>
        <div className={classNames("fixed inset-0 z-[10000] flex justify-end", { "pointer-events-none": !open })}>
          <div
            className={classNames("absolute inset-0 bg-black/40 transition", {
              "opacity-0": !open,
              "opacity-100": open,
            })}
          ></div>
          <div
            ref={ref}
            className={classNames(
              "overflow-y-auto max-w-[290px] flex flex-col relative w-full h-full p-6 transition ease-in-out duration-300 shadow-lg bg-white transform",
              { "translate-x-full": !open, "translate-x-0": open }
            )}
          >
            <div className="flex justify-between mb-7">
              <div className="text-theme-primary font-bold text-lg">{dict.footer.VASPVT}</div>
              <IconButton size="h-8 w-8 text-lg text-black/50 hover:text-black" onClick={() => setOpen(false)}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </div>
            {user && (
              <div className="mb-6">
                <div className="px-4 font-bold text-xl text-center">{dict.common.userRoles[user.role]}</div>
                <div className="px-4 text-center">
                  {user.firstName} {user.lastName}
                </div>
                <div className="pt-6">
                  <Button
                    variant="contained"
                    color="primary"
                    className="w-full"
                    onClick={() => {
                      navigate("/");
                      logout();
                      setOpen(false);
                    }}
                  >
                    {dict.header.logout}
                  </Button>
                </div>
              </div>
            )}
            {!user && (
              <div className="pb-6">
                <Button
                  variant="contained"
                  color="primary"
                  className="w-full"
                  onClick={() => navigate(ROUTES.login.home)}
                >
                  {dict.header.login}
                </Button>
              </div>
            )}
            {dashboardLinks &&
              dashboardLinks.map((link, i) => (
                <Link
                  key={`dashboard-${i}`}
                  onClick={() => setOpen(false)}
                  className="block -mx-6 px-6 py-2 hover:bg-theme-gray100"
                  to={link.path}
                >
                  {link.title}
                </Link>
              ))}
            {dashboardLinks && <div className="border-t my-2"></div>}
            {links.map((link, i) => (
              <a
                key={ids[i]}
                onClick={() => setOpen(false)}
                className="block -mx-6 px-6 py-2 hover:bg-theme-gray100"
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.title}
              </a>
            ))}
          </div>
        </div>
      </Portal>
    </>
  );
};

export default MenuButton;
