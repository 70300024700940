import { FunctionComponent, ReactElement, useState } from "react";
import { usePopper } from "react-popper";
import useClickAway from "react-use/lib/useClickAway";
import { Placement } from "@popperjs/core";
import { Portal } from "react-portal";

interface TooltipState {
  isOpen: boolean;
  close: () => void;
  setOpen: (val: boolean) => void;
  buttonWidth: number;
}

interface TooltipProps {
  Button: (props: TooltipState) => ReactElement;
  Popup: (props: TooltipState) => ReactElement;
  placement: Placement;
  trigger?: "click" | "hover";
  onClickAway?: () => void;
  offset?: number;
}

const Tooltip: FunctionComponent<TooltipProps> = ({ Button, Popup, placement, offset = 5, onClickAway, trigger = "click" }) => {
  const [open, setOpen] = useState(false);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ name: "offset", options: { offset: [0, offset] } }],
    strategy: "fixed",
  });
  useClickAway({ current: popperElement }, (e) => {
    if (!referenceElement?.contains(e.target as HTMLElement)) {
      setOpen(false);
      onClickAway?.();
    }
  });

  const state: TooltipState = {
    isOpen: open,
    close: () => setOpen(false),
    setOpen,
    buttonWidth: referenceElement?.getBoundingClientRect().width ?? 0,
  };

  const buttonProps =
    trigger === "click"
      ? { onClick: () => setOpen((prev) => !prev) }
      : trigger === "hover"
      ? { onMouseOver: () => setOpen(true), onMouseOut: () => setOpen(false) }
      : {};

  return (
    <div>
      <div {...buttonProps} ref={setReferenceElement}>
        {Button(state)}
      </div>
      {!open ? null : (
        <Portal>
          <div className="z-50" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            {Popup(state)}
          </div>
        </Portal>
      )}
    </div>
  );
};

export default Tooltip;
