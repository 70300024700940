import { useRef } from "react";

export const useScrollIntoView = () => {
  const ref = useRef<HTMLDivElement>(null);

  const scroll = (options?: ScrollIntoViewOptions) => {
    ref.current?.scrollIntoView(options);
  };

  return [<div ref={ref} />, scroll] as const;
};
