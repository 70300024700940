import { useMutation } from "@tanstack/react-query";
import apiClient from "api/api-client";
import { AxiosResponse } from "axios";
import { ApiError } from "types/api";

type SendFormData = {
  textAnswer?: string;
  questionnaireQuestion: number;
  selectedOptions?: { selectedOption?: number; text?: string }[];
};

type EvaluationQuestionnaireUserRecordArgs = {
  submissionData: { questionnaireQuestionUserRecords: SendFormData[] };
  publicId: string;
};

export const useUpdateEvaluationQuestionnaireUserRecord = () => {
  return useMutation<
    AxiosResponse<SendFormData | ApiError>,
    ApiError,
    EvaluationQuestionnaireUserRecordArgs
  >({
    mutationFn: async ({ submissionData, publicId }) =>
      apiClient.put<SendFormData>(
        `/public/evaluation-questionnaire-user-record/${publicId}`,
        submissionData
      ),
  });
};
