export const COLORS = {
  primary: "#20694B",
  text: "#212B36",
  gray50: "#E2E2E2",
  gray100: "#F4F6F8",
  gray200: "#E8F6F5",
  gray300: "#637381",
  border: "#E5E8EB",
  warning: "#FFA500",
  orange: "#F78E34",
  error: "#B22222",
  star: "#FFB22A",
  info: "#0668D6",
  background: "#FAFAFA",
  grayTab: "#E0E0E0",
  siteBackground: "#F0F0F0",
  pending: "#3892F4",
};
