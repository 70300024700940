import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { ApiListResponse, ListParams } from "types/api";
import { useScrollIntoView } from "./use-scroll-into-view";
import { useLoading } from "providers/loading";
import { getEmptyListResponse } from "utils/get-empty-list-response";
import { AxiosResponse } from "axios";

interface UseListOptions<T> {
  queryFn: (params: ListParams) => Promise<AxiosResponse<ApiListResponse<T>, any>>;
  keys: any[];
  enabled?: boolean;
  limit?: number;
}

export const useList = <T>({ queryFn, keys, enabled = true, limit: initialLimit = 10 }: UseListOptions<T>) => {
  const [page, setPage] = useState(1);
  const [limit, onLimit] = useState(initialLimit);
  const [tableTop, scrollToTop] = useScrollIntoView();
  const [filters, setFilters] = useState<ListParams["filters"]>({});

  const onPage = (val: number) => {
    setPage(val);
    setTimeout(() => {
      scrollToTop({ behavior: "smooth", block: "center" });
    }, 0);
  };

  const { data, isPending, isPlaceholderData } = useQuery<ApiListResponse<T>>({
    placeholderData: keepPreviousData,
    queryKey: [...keys, { page, limit, filters }],
    queryFn: async () => {
      const offset = (page - 1) * limit;
      const { status, data } = await queryFn({ page, limit, offset, filters });
      if (status >= 200 && status < 300) {
        setHasMore(data.page < data.pages);
      }
      return status >= 200 && status < 300 ? (data as ApiListResponse<T>) : getEmptyListResponse<T>();
    },
    enabled,
  });

  let isLoading = isPending || isPlaceholderData;

  if (!enabled) {
    isLoading = false;
  }

  const { addLoader, removeLoader } = useLoading();

  useEffect(() => {
    if (isLoading) {
      const loaderId = addLoader();
      return () => removeLoader(loaderId);
    }
  }, [isLoading, addLoader, removeLoader]);

  const pages = data?.pages ?? 1;
  const count = data?.count ?? 0;
  const items = data?.items ?? [];

  const [hasMore, setHasMore] = useState(true);

  const loadMore = () => {
    if (hasMore) {
      onLimit((currentLimit) => currentLimit + 100);
    } else {
      return;
    }
  };

  return {
    items,
    page: data?.page ?? 1,
    pages,
    count,
    onPage,
    tableTop,
    limit,
    onLimit,
    filters,
    setFilters,
    isLoading,
    loadMore,
  };
};
