import classNames from "classnames";
import { useDictionary } from "hooks/use-dictionary";
import useFormattedDate from "hooks/use-formatted-date";
import { ComponentProps } from "react";
import { Event, EventVersion } from "types/event";

interface EventDatesProps extends ComponentProps<"div"> {
  event: Event;
  versions?: EventVersion[];
}

const EventDates = ({ event, versions = [], className, ...props }: EventDatesProps) => {
  const dict = useDictionary();
  const eventRegistrationDate = useFormattedDate(event.startDate);
  const eventUpdatedAt = useFormattedDate(event.updatedAt);

  return (
    <div className={classNames("space-y-6", className)} {...props}>
      <div className="space-y-2">
        <div className="font-semibold">{dict.organizerAdmin.events.eventStartDate}</div>
        <div>{eventRegistrationDate}</div>
      </div>
      <div className="space-y-2">
        <div className="font-semibold">{dict.organizerAdmin.events.programUpdateDate}</div>
        <div>{eventUpdatedAt}</div>
      </div>
    </div>
  );
};

export default EventDates;
