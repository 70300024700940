import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { FunctionComponent, PropsWithChildren, createContext, useContext, useState } from "react";
import uniqueId from "lodash/uniqueId";

interface LoadingProviderProps extends PropsWithChildren {}

const LoadingContext = createContext<{
  isLoading: boolean;
  addLoader: () => string;
  removeLoader: (val: string) => void;
  cleanLoaders: () => void;
}>({} as any);

export const useLoading = () => useContext(LoadingContext);

const LoadingProvider: FunctionComponent<LoadingProviderProps> = ({ children }) => {
  const [loaders, setLoaders] = useState<string[]>([]);

  const addLoader = () => {
    const id = uniqueId();
    setLoaders((prev) => [...prev, id]);
    return id;
  };

  const removeLoader = (id: string) => {
    setLoaders((prev) => {
      let arr = [...prev];
      const index = prev.findIndex((x) => x === id);
      if (index !== -1) {
        arr.splice(index, 1);
      }
      return arr;
    });
  };

  const cleanLoaders = () => {
    setLoaders([]);
  };

  const isLoading = loaders.length > 0;

  return (
    <LoadingContext.Provider value={{ isLoading, addLoader, removeLoader, cleanLoaders }}>
      {!isLoading ? null : (
        <Backdrop open sx={{ zIndex: 1000000 }}>
          <CircularProgress color="inherit" className="text-white" />
        </Backdrop>
      )}
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
