import axios from "axios";
import { API_URL, TOKEN } from "constants/api";

const apiClient = axios.create({
  baseURL: `${API_URL}/api`,
  validateStatus: () => true,
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default apiClient;
