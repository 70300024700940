import { OrganizerInstitution } from "./institution/organizer";
import { HealthcareInstitution } from "./institution/healthcare";

export enum UserRole {
  ROLE_SYSTEM_ADMIN = "ROLE_ADMIN",
  ROLE_HYGIENE_INSTITUTION_USER = "ROLE_HYGIENE_INSTITUTION_USER",
  ROLE_QUESTIONNAIRE_DEVELOPER_USER = "ROLE_QUESTIONNAIRE_DEVELOPER_USER",
  ROLE_EVALUATOR_USER = "ROLE_EVALUATOR_USER",
  ROLE_CONTROLLING_AUTHORITY_USER = "ROLE_CONTROLLING_AUTHORITY_USER",
  ROLE_HEALTHCARE_EMPLOYEE = "ROLE_HEALTHCARE_EMPLOYEE",
  ROLE_HEALTHCARE_ADMIN = "ROLE_HEALTHCARE_ADMIN",
  ROLE_ORGANIZER_EMPLOYEE = "ROLE_ORGANIZER_EMPLOYEE",
  ROLE_ORGANIZER_ADMIN = "ROLE_ORGANIZER_ADMIN",
  ROLE_ANALYST_USER = "ROLE_ANALYST_USER",
  ROLE_SPECIALIST_USER = "ROLE_SPECIALIST_USER",
}

export enum MentorRole {
  ROLE_SPECIALIST_MENTOR_USER = "ROLE_SPECIALIST_MENTOR_USER",
}

export enum UserStatus {
  "ACTIVE" = "ACTIVE",
  "PENDING" = "PENDING",
}

export interface User {
  id: number;
  role: UserRole;
  email: string;
  firstName: string;
  lastName: string;
  status: UserStatus;
  organizerInstitution?: OrganizerInstitution | null;
  healthcareInstitution?: HealthcareInstitution | null;
  enabledSystemNotifications: boolean;
  enabledSystemNotificationsEmail: boolean;
}

export interface FormUser extends Omit<User, "id" | "status" | "institution" | "role"> {
  role?: UserRole;
}

export interface BodyUser extends Omit<User, "id" | "institution" | "role"> {
  institution?: number;
  role?: UserRole;
}

export type UserFilters = {
  q: string | null;
  roles: UserRole[] | null;
  email: string | null;
};
