import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import classNames from "classnames";
import { Control, Controller, FieldError, FieldValues, Path } from "react-hook-form";
import { useId } from "react-id-generator";
import { useDictionary } from "hooks/use-dictionary";
import { TextField } from "@mui/material";

type OptionType = {
  value: number;
  label: string | null;
  textRequired: boolean;
};
type SelectedOption = { selectedOption: number };

interface SurveyCheckboxGroupProps<T extends FieldValues> {
  options: OptionType[];
  control: Control<T>;
  name: Path<T>;
  label: string;
  disabled?: boolean;
}

const SurveyCheckboxGroup = <T extends FieldValues>({
  options,
  control,
  name,
  label,
  disabled = false,
  ...rest
}: SurveyCheckboxGroupProps<T>) => {
  const ids = useId(options.length);
  const dict = useDictionary();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange: fieldOnChange }, fieldState: { error } }) => {
        const getTextFieldValue = (optionValue: number) => {
          const option = value?.find((item: SelectedOption) => item.selectedOption === optionValue);
          return option?.text || "";
        };

        const canEnterTextFieldValue = (optionValue: number) => {
          const option = value?.find((item: SelectedOption) => item.selectedOption === optionValue);
          return !!option;
        };

        const getTextFieldErrorMessage = (fieldErrors: FieldError | undefined): string | undefined => {
          if (!fieldErrors) return undefined;

          if (Array.isArray(fieldErrors)) {
            return fieldErrors[fieldErrors.length - 1]?.text?.message;
          }
        };

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>, option: OptionType) => {
          const newValue = Number(event.target.value);
          const newObject = {
            selectedOption: newValue,
            textRequired: option.textRequired,
          };
          let updatedArray = Array.isArray(value) ? [...value] : [];
          if (value?.some((item: SelectedOption) => item.selectedOption === newValue)) {
            updatedArray = updatedArray.filter((item) => item.selectedOption !== newValue);
          } else {
            updatedArray.push(newObject);
          }

          if (updatedArray.length === 0) {
            fieldOnChange(undefined);
          } else {
            fieldOnChange(updatedArray);
          }
        };

        const handleTextChange = (
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          option: OptionType
        ) => {
          const text = event.target.value;
          let updatedArray = Array.isArray(value) ? [...value] : [];
          const optionIndex = updatedArray.findIndex((item: SelectedOption) => item.selectedOption === option.value);

          if (optionIndex !== -1) {
            updatedArray[optionIndex].text = text;
          } else {
            updatedArray.push({
              selectedOption: option.value,
              text,
              textRequired: option.textRequired,
            });
          }

          fieldOnChange(updatedArray);
        };

        return (
          <FormControl error={!!error}>
            <FormLabel
              classes={{
                root: classNames("!mb-2", disabled || !!error ? "!text-theme-text" : ""),
              }}
            >
              {label}
            </FormLabel>
            <FormGroup color="primary" {...rest}>
              {options.map((option, i) => {
                const isChecked =
                  Array.isArray(value) && value.some((item: SelectedOption) => item.selectedOption === option.value);

                return (
                  <FormControlLabel
                    key={`checkbox-${ids[i]}-${i}`}
                    value={option.value}
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={(e) => handleChange(e, option)}
                        inputProps={{
                          "aria-label": option.textRequired
                            ? dict.common.ariaLabels.freeFormAnswer
                            : option.label ?? "",
                        }}
                        disabled={disabled}
                      />
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        width: "100%",
                        "@media (min-width: 640px)": {
                          width: "50%",
                        },
                      },
                    }}
                    label={
                      option.textRequired ? (
                        <TextField
                          fullWidth
                          disabled={disabled || !canEnterTextFieldValue(option.value)}
                          placeholder={dict.surveys.freeFormAnswer}
                          error={!!getTextFieldErrorMessage(error)}
                          helperText={getTextFieldErrorMessage(error)}
                          value={getTextFieldValue(option.value)}
                          onChange={(e) => {
                            handleTextChange(e, option);
                          }}
                          inputProps={{
                            sx: {
                              "&::placeholder": {
                                fontWeight: "500",
                                color: "#000000",
                              },
                            },
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "#4B5563",
                            },
                          }}
                        />
                      ) : (
                        option.label
                      )
                    }
                  />
                );
              })}
            </FormGroup>
            {error && <FormHelperText>{error.message}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

export default SurveyCheckboxGroup;
