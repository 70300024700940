import { Classifier, ClassifierValue } from "./classifier";
import { SurveyQuestionTypeEnum, SurveyRespondentStatusEnum } from "types/survey";
import { FileRecord } from "./program/file-record";
import { QuestionnaireStatus } from "./development-plan";

export type QuestionnairesFilters = {
  q: string | null;
};

export enum EvaluationQuestionnaireUserRecordStatusEnum {
  NEW = "NEW",
  SUBMITTED = "SUBMITTED",
}

export enum QuestionnaireType {
  DEVELOPMENT_PLAN_QUESTIONNAIRE = "DEVELOPMENT_PLAN_QUESTIONNAIRE",
  EVALUATION_QUESTIONNAIRE = "EVALUATION_QUESTIONNAIRE",
}

export enum ExternalEvaluationFormsTabType {
  EXTERNAL_EVALUATION_FORMS = "EXTERNAL_EVALUATION_FORMS",
  STATISTICS_OF_SENT_EXTERNAL_EVALUATION_FORMS = "STATISTICS_OF_SENT_EXTERNAL_EVALUATION_FORMS",
}

export type ExternalEvaluationFormSendingType = {
  emails: { email: string }[];
  subject: string;
  text: string;
};

export type LicenseTypeClassifierValue = Pick<ClassifierValue, "id" | "valueName"> & Pick<Classifier, "title">;

export interface DevelopmentQuestionnaireVersion {
  id: number;
  data: DevelopmentQuestionnaire;
  createdAt: string;
}

export interface Questionnaire {
  id: number;
  title: string;
  licenseTypeClassifiers: LicenseTypeClassifierValue[];
  evaluatorType: string | null;
}

export interface DevelopmentQuestionnaire {
  id: number;
  title: string;
  description: string;
  licenseTypeClassifiers: LicenseTypeClassifierValue[];
  questionnaireQuestions: DevelopmentQuestionnaireQuestion[];
  developmentPlans: { id: number }[];
}

export interface FormDevelopmentQuestionnaire {
  title: string;
  description: string;
  licenseTypeClassifiers: string[];
  questionnaireQuestions: FormDevelopmentQuestionnaireQuestion[];
}

export interface SendFormDevelopmentQuestionnaire {
  title: string;
  description: string;
  licenseTypeClassifiers?: string[];
  questionnaireQuestions: SendFormDevelopmentQuestionnaireQuestion[];
}

export interface DevelopmentQuestionnaireQuestion {
  id: number;
  title: string;
  text: string;
  required: boolean;
  type: SurveyQuestionTypeEnum;
  files?: (File | FileRecord)[];
  questionnaireQuestionOptions?: FormQuestionnaireQuestionOption[];
  statsTextValues?: string[];
}

export interface FormDevelopmentQuestionnaireQuestion {
  id?: number;
  title: string;
  text: string;
  required: boolean;
  type: SurveyQuestionTypeEnum;
  files?: (File | FileRecord)[];
  questionnaireQuestionOptions?: FormQuestionnaireQuestionOption[];
}

export interface SendFormDevelopmentQuestionnaireQuestion {
  id?: number;
  title: string;
  text: string;
  required: boolean;
  type: SurveyQuestionTypeEnum;
  files?: number[];
  questionnaireQuestionOptions?: FormQuestionnaireQuestionOption[];
}

export interface EvaluationQuestionnaire {
  id: number;
  title: string;
  evaluatorType: string;
  minRespondentCount: number;
  questionnaireQuestions: EvaluationQuestionnaireQuestion[];
}

export interface EvaluationQuestionnaireStatistics {
  id: number;
  title: string;
  evaluatorType: string;
  minRespondentCount: number;
  questionnaireQuestions: EvaluationQuestionnaireQuestion[];
}

export interface FormEvaluationQuestionnaire {
  title: string;
  evaluatorType: string;
  minRespondentCount: number;
  questionnaireQuestions: FormEvaluationQuestionnaireQuestion[];
}

export interface SendFormEvaluationQuestionnaire {
  title: string;
  evaluatorType: string;
  minRespondentCount: number;
  questionnaireQuestions: SendFormEvaluationQuestionnaireQuestion[];
}

export interface EvaluationQuestionnaireQuestion {
  id: number;
  text: string;
  required: boolean;
  type: SurveyQuestionTypeEnum;
  files?: (File | FileRecord)[];
  questionnaireQuestionOptions?: FormQuestionnaireQuestionOption[];
  statsTextValues?: string[];
}

export interface FormEvaluationQuestionnaireQuestion {
  id?: number;
  text: string;
  required: boolean;
  type: SurveyQuestionTypeEnum;
  files?: (File | FileRecord)[];
  questionnaireQuestionOptions?: FormQuestionnaireQuestionOption[];
}

export interface SendFormEvaluationQuestionnaireQuestion {
  id?: number;
  text: string;
  required: boolean;
  type: SurveyQuestionTypeEnum;
  files?: number[];
  questionnaireQuestionOptions?: FormQuestionnaireQuestionOption[];
}

export interface FormQuestionnaireQuestionOption {
  id?: number;
  key?: string;
  value: string;
  stats?: number;
}

export enum SendFormQuestionnaireQuestionUserRecordStatusEnum {
  NEW = "NEW",
  DRAFT = "DRAFT",
  DONE = "DONE",
}

export interface SendFormQuestionnaireQuestionUserRecords {
  questionnaireSpecialistUserRecordStatus: SendFormQuestionnaireQuestionUserRecordStatusEnum;
  questionnaireQuestionUserRecords: SendFormQuestionnaireQuestionUserRecord[];
}

export interface SendFormQuestionnaireQuestionUserRecord {
  textAnswer?: string;
  questionnaireQuestion: number;
  selectedOptions: SendFormQuestionnaireQuestionUserRecordSelectedOption[];
}

export interface SendFormQuestionnaireQuestionUserRecordSelectedOption {
  selectedOption: number;
  text?: string;
}

export interface MenteeUserQuestionnaire {
  developmentPlanId: number;
  questionnaireId: number;
  questionnaireTitle: string;
  licenseTypeClassifiers: LicenseTypeClassifierValue[];
  status: MenteeUserQuestionnaireStatusEnum;
}

export enum MenteeUserQuestionnaireStatusEnum {
  NEW = "NEW",
  DRAFT = "DRAFT",
  DONE = "DONE",
  CLOSED = "CLOSED",
}

export const mapMenteeUserQuestionnaireStatusToSurveyRespondentStatus = (
  status: MenteeUserQuestionnaireStatusEnum
): SurveyRespondentStatusEnum => {
  switch (status) {
    case MenteeUserQuestionnaireStatusEnum.NEW:
      return SurveyRespondentStatusEnum.NEW;
    case MenteeUserQuestionnaireStatusEnum.DRAFT:
      return SurveyRespondentStatusEnum.DRAFT;
    case MenteeUserQuestionnaireStatusEnum.DONE:
      return SurveyRespondentStatusEnum.SUBMITTED;
    case MenteeUserQuestionnaireStatusEnum.CLOSED:
      return SurveyRespondentStatusEnum.CLOSED;
  }
};

export interface MenteeUserQuestionnaireQuestionUserRecord {
  id: number;
  textAnswer?: string;
  questionnaireQuestion: MenteeUserQuestionnaireQuestionUserRecordQuestionnaireQuestion;
  selectedOptions: MenteeUserQuestionnaireQuestionUserRecordSelectedOption[];
}

export interface MenteeUserQuestionnaireQuestionUserRecordQuestionnaireQuestion {
  id: number;
}

export interface MenteeUserQuestionnaireQuestionUserRecordSelectedOption {
  selectedOption: {
    id: number;
  };
  text?: string;
}

export interface MenteeUserDevelopmentPlanQuestionnaireUserRecords {
  questionnaireQuestionUserRecords: MenteeUserQuestionnaireQuestionUserRecord[];
  questionnaireSpecialistUserRecordStatus: QuestionnaireStatus;
}

export type MenteeUserDevelopmentPlanQuestionnairesFilter = {
  query: string | null;
};

export type MentorUserQuestionnaireFilters = {
  q: string | null;
  licenseTypeClassifiers: string[] | null;
};
