import { FunctionComponent, PropsWithChildren } from "react";
import { SnackbarProvider as BaseSnackbarProvider } from "notistack";
import BaseErrorSnackbar from "./snackbars/base-error";
import BaseSuccessSnackbar from "./snackbars/base-success";

interface SnackbarProviderProps extends PropsWithChildren {}

const SnackbarProvider: FunctionComponent<SnackbarProviderProps> = ({ children }) => {
  return (
    <BaseSnackbarProvider Components={{ baseError: BaseErrorSnackbar, baseSuccess: BaseSuccessSnackbar }}>
      {children}
    </BaseSnackbarProvider>
  );
};

export default SnackbarProvider;
