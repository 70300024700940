import Alert from "@mui/material/Alert";
import { useDictionary } from "hooks/use-dictionary";
import { ReactElement } from "react";

interface FiltersAlertProps<T> {
  filters: T;
  formatters: { [key in keyof T]?: (val: T[key]) => string | ReactElement };
}

const FiltersAlert = <T,>({ filters, formatters }: FiltersAlertProps<T>) => {
  const dict = useDictionary();
  const goodFilters = Object.entries(filters ?? {}).filter(([key, value]) => {
    return value != null && value !== "" && !(Array.isArray(value) && value.length === 0);
  });

  return !goodFilters.length ? null : (
    <Alert severity="info" variant="outlined">
      <div className="flex flex-wrap">
        <span className="mr-1">{dict.common.filterAlert.label}:</span>
        {goodFilters.map((x, i) => {
          const [key, value] = x as [keyof T, T[keyof T]];
          const formattedValue = formatters[key]
            ? formatters[key]?.(value)
            : `${key as string} (${value})`;
          return (
            <div key={`filter-${String(key)}-${i}`} className="flex">
              <span style={{ whiteSpace: "nowrap" }}>
                {formattedValue}
                {i < goodFilters.length - 1 && <span>, </span>}
              </span>
            </div>
          );
        })}
      </div>
    </Alert>
  );
};

export default FiltersAlert;
