import { forwardRef, useCallback, useState } from "react";
import { SnackbarContent, CustomContentProps, useSnackbar } from "notistack";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";
import classNames from "classnames";
import { ReadableError } from "utils/get-readable-errors";
import { useId } from "react-id-generator";

interface Props extends CustomContentProps {
  errors: ReadableError[];
}

const BaseErrorSnackbar = forwardRef<HTMLDivElement, Props>(({ id, message, errors, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const ids = useId(errors.length);

  return (
    <SnackbarContent ref={ref}>
      <div className="bg-white">
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <div className="p-3 bg-theme-error/10 text-sm space-y-4">
            {errors.map((error, i) => (
              <div key={ids[i]}>
                <div className="font-semibold mb-1">{error.label}</div>
                <ul className="space-y-0.5">
                  {error.errors.map((innerError, j) => (
                    <li key={ids[i] + j}>{innerError}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </Collapse>
        <Alert
          variant="outlined"
          severity="error"
          action={
            <div className="flex gap-1">
              {!errors.length ? null : (
                <IconButton
                  className={classNames("!transition", { "transform rotate-[90deg]": expanded })}
                  aria-label="Show more"
                  size="small"
                  onClick={handleExpandClick}
                >
                  <ExpandMoreIcon />
                </IconButton>
              )}
              <IconButton size="small" onClick={handleDismiss}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          }
        >
          <div>{message}</div>
        </Alert>
      </div>
    </SnackbarContent>
  );
});

export default BaseErrorSnackbar;
