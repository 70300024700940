import TableBase from "components/table-base";
import { useDictionary } from "hooks/use-dictionary";
import { FunctionComponent } from "react";
import { useId } from "react-id-generator";
import { MandatoryTopicClassifier } from "types/program";
import { interpolate } from "utils/interpolate";

interface TopicsCompositionProps {
  topics: MandatoryTopicClassifier[];
}

const TopicsComposition: FunctionComponent<TopicsCompositionProps> = ({ topics }) => {
  const ids = useId(topics.length);
  const dict = useDictionary();

  return (
    <TableBase>
      <thead>
        <tr>
          <th>{dict.organizerAdmin.events.mandatoryTopic}</th>
          <th>{dict.organizerAdmin.events.duration}</th>
        </tr>
      </thead>
      <tbody>
        {topics.map((topic, i) => (
          <tr key={ids[i]}>
            <td>{topic.classifier.valueName}</td>
            <td className="whitespace-nowrap">
              {interpolate(dict.organizerAdmin.events.durationValue, { value: topic.duration })}
            </td>
          </tr>
        ))}
      </tbody>
    </TableBase>
  );
};

export default TopicsComposition;
