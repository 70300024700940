import { Button } from "@mui/material"
import Layout from "./layout"
import { useDictionary } from "hooks/use-dictionary"
import { useNavigate } from "hooks/use-navigate";
import { useCallback } from "react";

export type ErrorPageProps = {
  resetError: () => void;
}

export const ErrorPage = ({ resetError }: ErrorPageProps) => {
  const dict = useDictionary();
  const navigate = useNavigate();

  const handleErrorReset = useCallback(() => {
    resetError();
    navigate("/");
  }, [resetError, navigate]);

  return (
    <Layout>
      <div className="min-h-[500px] flex flex-1">
        <div className="m-auto text-center">
          <div className="mb-6 text-[36px] font-bold leading-none">{dict.errorPage.title}</div>
          <div className="mb-6 max-w-[350px]">{dict.errorPage.description}</div>
          <Button onClick={handleErrorReset} variant="contained" color="primary" className="mx-auto">
            {dict.errorPage.button}
          </Button>
        </div>
      </div>
    </Layout>
  )
}