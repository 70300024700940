import { NavigateFunction, useNavigate as useDefaultNavigate } from "react-router-dom";

export const useNavigate: typeof useDefaultNavigate = () => {
  const defaultNavigate = useDefaultNavigate();

  const navigate = (...params: Parameters<typeof defaultNavigate>) => {
    defaultNavigate(...params);
    window.scrollTo(0, 0);
  };

  return navigate as NavigateFunction;
};
