import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { useDictionary } from "hooks/use-dictionary";
import { useAuth } from "providers/auth";
import Layout from "components/layout";

type SingleSignOnData = {
  ssoSession: string;
  specialistPersonalCode: string;
  licenseTypeClassifierId: string;
  specialistRequestDate: string;
};

const SingleSignOnPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dict = useDictionary();
  const { loginSingleSignOn } = useAuth();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const ssoSession = searchParams.get("ssoSession");
    const licenseTypeClassifierId = searchParams.get("licenseTypeClassifierId");
    const specialistPersonalCode = searchParams.get("specialistPersonalCode");
    const specialistRequestDate = searchParams.get("specialistRequestDate");

    if (ssoSession && licenseTypeClassifierId && specialistPersonalCode && specialistRequestDate) {
      authenticateAndSendData({
        ssoSession,
        specialistPersonalCode,
        licenseTypeClassifierId,
        specialistRequestDate,
      });
    } else {
      console.error(dict.messages.error);
    }
  }, [location.search]);

  const authenticateAndSendData = async ({ ssoSession, ...restData }: SingleSignOnData) => {
    const response = await loginSingleSignOn(ssoSession);

    if (response.success) {
      navigate(ROUTES.user + ROUTES.controllingAuthority.specialistSearch, {
        state: {
          specialistCode: restData.specialistPersonalCode,
          licenseTypeClassifierId: restData.licenseTypeClassifierId,
          specialistRequestDate: restData.specialistRequestDate,
        },
      });
    }
  };

  return (
    <Layout>
      <div className="flex justify-center items-center h-screen text-2xl">{dict.common.loading}</div>
    </Layout>
  );
};

export default SingleSignOnPage;
