import classNames from "classnames";
import Close from "@mui/icons-material/CloseOutlined";
import Check from "@mui/icons-material/CheckOutlined";
import Pause from "@mui/icons-material/PauseOutlined";
import { useMemo } from "react";

type GenericStatusTagProps = {
  type: "primary" | "error" | "info" | "neutral" | "warning";
  text: string;
};

export const GenericStatusTag = ({ type, text }: GenericStatusTagProps) => {
  const Icon = useMemo(() => {
    const icons = {
      primary: Check,
      info: Check,
      warning: Check,
      error: Close,
      neutral: Pause,
    };

    return icons[type];
  }, [type]);

  return (
    <div className="mr-2 mb-2 bg-theme-gray100 rounded-full text-xs flex justify-center items-center leading-7 px-3 gap-1.5 ">
      <div
        className={classNames("w-4 h-4 rounded-full flex items-center justify-center flex-shrink-0", {
          "bg-theme-primary text-white": "primary" === type,
          "bg-theme-info text-white": "info" === type,
          "bg-theme-error text-white ": "error" === type,
          "bg-theme-gray300 text-white": "neutral" === type,
          "bg-theme-warning text-white": "warning" === type,
        })}
      >
        <Icon className="text-white !w-3 !h-3 m-auto !stroke-2" />
      </div>
      <div className="text-center whitespace-nowrap">{text}</div>
    </div>
  );
};
