import { useLoading } from "providers/loading";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const LoadingCleanup = () => {
  const location = useLocation();
  const { cleanLoaders } = useLoading();

  useEffect(() => {
    cleanLoaders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <div />;
};

export default LoadingCleanup;
