import { useLoading } from "providers/loading";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import EventDates from "./components/event-dates";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "components/link";
import { ROUTES } from "constants/routes";
import { useDictionary } from "hooks/use-dictionary";
import EventByDevelopmentProgramView from "./by-development-program/by-development-program";
import { DevelopmentProgram, LearningProgram, ProgramType } from "types/program";
import EventByLearningProgramView from "./by-learning-program/by-learning-program";
import { usePublicEvent } from "hooks/api/use-get-public-event";
import Layout from "components/layout";
import { Container } from "@mui/material";
import EventCard from "./components/event-card";
import { isDevelopmentType, isLearningType } from "utils/program-guards";

const EventViewPage = () => {
  const { id } = useParams();

  const { data: event, isLoading } = usePublicEvent(id);

  const { addLoader, removeLoader } = useLoading();

  useEffect(() => {
    if (isLoading) {
      const loaderId = addLoader();
      return () => removeLoader(loaderId);
    }
  }, [isLoading, addLoader, removeLoader]);

  const dict = useDictionary();

  if (!event) {
    return null;
  }

  return (
    <Layout>
      <Container>
        <div className="space-y-6 py-10">
          <Breadcrumbs>
            <Link to={ROUTES.home}>{dict.common.breadcrumbsRoot}</Link>
            <Link to={ROUTES.home + ROUTES.events}>{dict.organizerAdmin.events.events}</Link>
            <div>{event.name}</div>
          </Breadcrumbs>
          <div className="flex justify-between">
            <div className="text-2xl font-bold">{dict.organizerAdmin.events.eventView}</div>
          </div>
          <div className="bg-white border rounded">
            <EventCard event={event} className="border-b p-6" />
            <EventDates event={event} className="border-b p-6" />
            {isDevelopmentType(event.program) && (
              <EventByDevelopmentProgramView event={event} program={event.program} />
            )}
            {isLearningType(event.program) && <EventByLearningProgramView event={event} program={event.program} />}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default EventViewPage;
