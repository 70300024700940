import { QUERY_KEYS } from "constants/query-keys";
import apiClient from "api/api-client";
import { StaticContent } from "types/static-content";
import { useList } from "hooks/use-list";
import { ApiListResponse } from "types/api";
import queryString from "query-string";

export const useNewsList = (limit?: number) => {
  return useList({
    keys: [QUERY_KEYS.staticContentList],
    queryFn: async ({ offset, limit: defaultLimit }) => {
      return await apiClient.get<ApiListResponse<StaticContent>>(
        `/public/news?${queryString.stringify(
          { offset, limit: limit ?? defaultLimit },
          { skipNull: true, skipEmptyString: true }
        )}`
      );
    },
  });
};

