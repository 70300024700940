import { FormControlLabel, Radio, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import React from "react";
import { Control, Controller, FieldError } from "react-hook-form";
import { FormQuestionnaireResponse } from "pages/public/external-evaluation-form-answer/form/external-evaluation-response-form";
import { useDictionary } from "hooks/use-dictionary";

type OptionType = { id: number; key: string; value: string | null; textRequired: boolean };

type GroupedOptionsType = {
  key: string;
  options: OptionType[];
};

interface QuestionnaireRatingGroupProps {
  options: OptionType[];
  control: Control<FormQuestionnaireResponse>;
  answerIndex: number;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

interface CustomFieldError extends FieldError {
  selectedOption?: {
    message: string;
  };
  text?: {
    message: string;
  };
}

const QuestionnaireRatingGroup = ({
  options,
  control,
  answerIndex,
  disabled = false,
}: QuestionnaireRatingGroupProps) => {
  const dict = useDictionary();
  const groupedOptionsArray: GroupedOptionsType[] = options.reduce(
    (acc: GroupedOptionsType[], option) => {
      let group = acc.find((g) => g.key === option.key);
      if (!group) {
        group = { key: option.key, options: [] };
        acc.push(group);
      }

      group.options.push(option);
      return acc;
    },
    []
  );
  return (
    <div className="flex gap-3">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4" style={{ width: "100%" }}>
        {groupedOptionsArray.map(({ key, options }, index) => (
          <Controller
            key={index}
            name={`questionnaireQuestionUserRecords.${answerIndex}.selectedOptions.${index}`}
            control={control}
            render={({ field, fieldState: { error } }) => {
              const customError = error as CustomFieldError;
              return (
                <React.Fragment key={index}>
                  <FormControl error={!!error}>
                    <div className="flex flex-col w-full">
                      <FormLabel>{key}</FormLabel>
                      <RadioGroup value={field?.value?.selectedOption ?? ""}>
                        {options.map((option) => (
                          <FormControlLabel
                            key={option.id}
                            value={option.id}
                            control={
                              <Radio
                                disabled={disabled}
                                onClick={(e) => {
                                  const targetValue = (e.target as HTMLInputElement).value;
                                  if (field?.value?.selectedOption === parseInt(targetValue)) {
                                    field.onChange(undefined);
                                  } else {
                                    const newValue = parseInt(targetValue);
                                    const newObject = {
                                      selectedOption: newValue,
                                      textRequired: option.textRequired,
                                    };
                                    field.onChange(newObject);
                                  }
                                }}
                              />
                            }
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                width: "100%",
                                "@media (min-width: 640px)": {
                                  width: "50%",
                                },
                              },
                            }}
                            label={
                              option.textRequired ? (
                                <TextField
                                  fullWidth
                                  disabled={disabled || field?.value?.selectedOption !== option.id}
                                  placeholder={dict.surveys.freeFormAnswer}
                                  error={!!customError?.text}
                                  helperText={customError?.text?.message}
                                  value={field?.value?.text ?? ""}
                                  onChange={(e) => {
                                    const updatedValue = {
                                      selectedOption: option.id,
                                      text: e.target.value,
                                      textRequired: option.textRequired,
                                    };
                                    field.onChange(updatedValue);
                                  }}
                                />
                              ) : (
                                option.value
                              )
                            }
                          />
                        ))}
                      </RadioGroup>
                    </div>
                    <FormHelperText>{customError?.selectedOption?.message}</FormHelperText>
                  </FormControl>
                </React.Fragment>
              );
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default QuestionnaireRatingGroup;
