import { ThemeProvider } from "@emotion/react";
import theme from "theme";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AuthProvider from "providers/auth";
import Pages from "pages";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "query-client";
import LoadingProvider from "providers/loading";
import SnackbarProvider from "providers/snackbar";

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="lt">
          <SnackbarProvider>
            <AuthProvider>
              <LoadingProvider>
                <Pages />
              </LoadingProvider>
            </AuthProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
