import { forwardRef, useCallback } from "react";
import { SnackbarContent, CustomContentProps, useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";

interface Props extends CustomContentProps {}

const BaseSuccessSnackbar = forwardRef<HTMLDivElement, Props>(({ id, message, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <div className="bg-white">
        <Alert
          variant="outlined"
          severity="success"
          action={
            <IconButton size="small" onClick={handleDismiss}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          <div>{message}</div>
        </Alert>
      </div>
    </SnackbarContent>
  );
});

export default BaseSuccessSnackbar;
