import classNames from "classnames";
import { ComponentProps, FunctionComponent } from "react";

interface ContainerProps extends ComponentProps<"div"> {
  childClassName?: string;
}

const Container: FunctionComponent<ContainerProps> = ({ children, className, childClassName = "", ...props }) => {
  return (
    <div className={classNames(className, "sm:px-9 px-4")} {...props}>
      <div className={classNames(childClassName, "max-w-[1340px] w-full mx-auto")}>{children}</div>
    </div>
  );
};

export default Container;
