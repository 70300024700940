import { FunctionComponent, PropsWithChildren } from "react";
import Header from "./header";
import Footer from "./footer";
import { DashboardLink } from "./dashboard/layout";

interface LayoutProps extends PropsWithChildren {
  links?: DashboardLink[];
}

const Layout: FunctionComponent<LayoutProps> = ({ links, children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header dashboardLinks={links} />
      <div className="flex-grow bg-theme-background">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
