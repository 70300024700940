import apiClient from "api/api-client";
import { QUERY_KEYS } from "constants/query-keys";
import { useGetOne } from "hooks/use-get-one";
import { Event } from "types/event";

export const usePublicEvent = (id?: string) => {
  return useGetOne({
    keys: [QUERY_KEYS.events, id],
    queryFn: async () => {
      return apiClient.get<Event>(`/public/events/${id}`);
    },
  });
};
