import { QUERY_KEYS } from "constants/query-keys";
import apiClient from "api/api-client";
import { EvaluationQuestionnaire } from "types/questionnaire";
import { useGetOne } from "hooks/use-get-one";

export const useGetExternalEvaluationForm = (publicId: string) => {
  return useGetOne<EvaluationQuestionnaire>({
    keys: [QUERY_KEYS.questionnaires, publicId],
    queryFn: async () => {
      return await apiClient.get<EvaluationQuestionnaire>(
        `/public/evaluation-questionnaires/${publicId}`
      );
    },
  });
};
