import { QUERY_KEYS } from "constants/query-keys";
import { useQuery } from "@tanstack/react-query";
import apiClient from "api/api-client";
import { StaticContent } from "types/static-content";

export const useGetNewsArticle = (id: number) => {
  return useQuery({
    queryKey: [QUERY_KEYS.newsArticleGet, id],
    queryFn: async () => {
      const response = await apiClient.get<StaticContent>(
        `/public/news/${id}`
      );
      return response.data;
    }
  });
};
