import Container from "components/container";
import UserButton from "./user-button";
import NotificationsButton from "./notifications-button";
import Search from "@mui/icons-material/Search";
import LoginIcon from "@mui/icons-material/Login";
import { useDictionary } from "hooks/use-dictionary";
import IconButton from "components/icon-button";
import MenuButton from "./menu-button";
import { useId } from "react-id-generator";
import Link from "components/link";
import { useAuth } from "providers/auth";
import { ROUTES } from "constants/routes";
import { DashboardLink } from "components/dashboard/layout";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  dashboardLinks?: DashboardLink[];
}

const Header: FunctionComponent<HeaderProps> = ({ dashboardLinks }) => {
  const dict = useDictionary();
  const { user } = useAuth();
  const navigate = useNavigate();

  const links = [{ title: dict.header.news, href: ROUTES.newsList }];

  const ids = useId(links.length);

  return (
    <div className="relative">
      <div className={"bg-theme-primary h-4"}></div>
      <Container className="shadow-[0px_2px_20px_0px_rgba(99,115,129,0.1)]">
        <div className="h-20 flex justify-between items-center">
          <Link to="/" className="flex gap-3 uppercase">
            <div className="text-theme-primary">VASPVT</div>
            <h1>
              <div>{dict.header.competencePlatform}</div>
            </h1>
          </Link>
          <div className="hidden lg:flex gap-6 px-10 items-center">
            {links.map((link, i) => (
              <a key={ids[i]} href={link.href} className="text-theme-gray300" target="_blank" rel="noopener noreferrer">
                {link.title}
              </a>
            ))}
            {user && <NotificationsButton user={user} />}
            {user && <UserButton />}
            {!user && (
              <IconButton onClick={() => navigate(ROUTES.login.home)}>
                <LoginIcon className="text-black items-center" />
              </IconButton>
            )}
          </div>
          <MenuButton className="lg:hidden" links={links} dashboardLinks={dashboardLinks} />
        </div>
      </Container>
    </div>
  );
};

export default Header;
