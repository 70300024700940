import { FunctionComponent, useMemo } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import { ASSETS_URL } from "constants/api";
import { FileRecord } from "types/program/file-record";

interface FileViewButtonProps {
  file: FileRecord | File;
}

const FileViewButton: FunctionComponent<FileViewButtonProps> = ({ file }) => {
  const fileName = useMemo(() => {
    if (file instanceof File) {
      return file.name;
    }

    return file.fileName;
  
  }, [file]);

  const fileUrl = useMemo(() => {
    if (file instanceof File) {
      return URL.createObjectURL(file);
    }

    return `${ASSETS_URL}${file.fileName}`;
  }, [file]);

  return (
    <div key={fileName} className="flex items-center space-x-2">
      <DescriptionIcon />
      <a
        target="_blank"
        rel="noreferrer"
        href={fileUrl}
        className="text-theme-primary hover:underline cursor-pointer"
      >
        {fileName}
      </a>
    </div>
  );
};

export default FileViewButton;
