import { yupResolver } from "@hookform/resolvers/yup";
import { SurveyQuestionTypeEnum } from "types/survey";
import * as yup from "yup";
import dict from "dictionaries/lt.json";

export const externalEvaluationResponseFormResolver = () => {
  const schema = yup.object().shape({
    questionnaireQuestionUserRecords: yup
      .array()
      .of(
        yup.object().shape({
          type: yup
            .mixed<SurveyQuestionTypeEnum>()
            .oneOf(Object.values(SurveyQuestionTypeEnum))
            .required(dict.form.requiredError),
          required: yup.boolean().required(dict.form.requiredError),
          textAnswer: yup.string().when(["type", "required"], ([type, required], schema) => {
            return type === SurveyQuestionTypeEnum.QUESTION_TYPE_OPEN && required
              ? schema.required(dict.form.requiredError)
              : schema.nullable();
          }),
          questionnaireQuestion: yup.number().required(dict.form.requiredError),
          selectedOptions: yup
            .array()
            .of(
              yup.object().shape({
                selectedOption: yup.number().when((values, schema, context) => {
                  const type = (context as any).from?.[1]?.value?.type;
                  const required = (context as any).from?.[1]?.value?.required;
                  return required &&
                    (type === SurveyQuestionTypeEnum.QUESTION_TYPE_RATING ||
                      type === SurveyQuestionTypeEnum.QUESTION_TYPE_RATING_OPEN)
                    ? schema.required(dict.form.requiredError)
                    : schema.nullable();
                }),
                text: yup.string().when("textRequired", ([textRequired], schema) => {
                  return textRequired
                    ? schema.required(dict.form.requiredError)
                    : schema.nullable();
                }),
                textRequired: yup.boolean(),
              })
            )
            .when(["type", "required"], ([type, required], schema) => {
              return type !== SurveyQuestionTypeEnum.QUESTION_TYPE_OPEN && required
                ? schema.required(dict.form.selectQuestionOption)
                : schema.nullable();
            }),
        })
      )
      .required(dict.form.requiredError),
  });

  return { schema, resolver: yupResolver(schema) };
};
