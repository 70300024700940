import { createBreakpoint } from "react-use";

const createdBreakpoint = createBreakpoint({
  laptopL: 1440,
  laptop: 1024,
  tablet: 768,
  mobile: 640,
  mobileM: 375, // Medium smartphones (e.g., iPhone 6/7/8)
  mobileS: 320, // Small smartphones (e.g., iPhone 5/SE)
});

export const useBreakpoint = () => {
  const breakpoint = createdBreakpoint();
  const isLaptopL = breakpoint === "laptopL";
  const isLaptop = breakpoint === "laptop";
  const isMobile = breakpoint === "mobileS" || breakpoint === "mobileM";
  const maxTablet = breakpoint === "tablet" || isMobile;
  const isMobileTailwind = breakpoint === "mobileS" || breakpoint === "mobileM" || breakpoint === "mobile";

  return {
    isLaptopL,
    isLaptop,
    maxTablet,
    isMobile,
    breakpoint,
    isMobileTailwind,
  };
};
