import Container from "components/container";
import Layout from "components/layout";
import SectionTitle from "../home/components/section-title";
import { useNewsList } from "hooks/api/use-news-list";
import NewsCard from "components/news-card";
import { useDictionary } from "hooks/use-dictionary";
import { Breadcrumbs, Link, Pagination } from "@mui/material";
import EmptyState from "components/empty-state";

export const NewsListPage = () => {
  const { items: news, pages, page, isLoading, onPage } = useNewsList();
  const dict = useDictionary();

  if (isLoading) {
    return null;
  }

  if (!isLoading && !news) {
    return null;
  }

  return (
    <Layout>
      <div className="space-y-8 py-12">
        <Container className="bg-theme-background" childClassName="space-y-4">
          <Breadcrumbs>
            <Link href="/">{dict.common.breadcrumbsRoot}</Link>
            <div>{dict.news.news}</div>
          </Breadcrumbs>
          <div className="space-y-5">
            <SectionTitle>{dict.news.news}</SectionTitle>
            <div className="grid md:grid-cols-2 gap-5">
              {news.map((newsArticle) => {
                return (
                  <NewsCard
                    id={newsArticle.id}
                    shortSummary={newsArticle.shortSummary}
                    title={newsArticle.title}
                    publishAt={newsArticle.publishAt}
                    key={newsArticle.id}
                  />
                );
              })}
            </div>
            {news.length === 0 && (
              <div className="flex flex-col items-center bg-white border py-2">
                <EmptyState text={dict.news.noNews} />
              </div>
            )}
            <Pagination
              count={pages}
              page={page}
              color="primary"
              hideNextButton={pages === 1}
              hidePrevButton={pages === 1}
              onChange={(e, page) => onPage(page)}
            />
          </div>
        </Container>
      </div>
    </Layout>
  );
};
