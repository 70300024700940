import { useDictionary } from "hooks/use-dictionary";
import PriorityHighIcon from "@mui/icons-material/PriorityHighOutlined";
import TagBase from "components/tag-base";

const MissingParticipantsTag = () => {
  const dict = useDictionary();

  return (
    <TagBase className="bg-theme-gray100 rounded-full text-xs flex items-center leading-7 px-3 gap-1.5">
      <div className="w-4 h-4 rounded-full flex bg-theme-error">
        <PriorityHighIcon className="text-white !w-3 !h-3 m-auto !stroke-2" />
      </div>
      <div>{dict.organizerAdmin.events.participantsListMissing}</div>
    </TagBase>
  );
};

export default MissingParticipantsTag;
