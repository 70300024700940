import Tooltip from "components/tooltip";
import { useAuth } from "providers/auth";
import { useDictionary } from "hooks/use-dictionary";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button } from "@mui/material";
import IconButton from "components/icon-button";

const UserButton = () => {
  const { logout, user } = useAuth();

  const dict = useDictionary();

  const navigate = useNavigate();

  if (!user) {
    return null;
  }

  return (
    <Tooltip
      placement="bottom"
      Popup={({ close, buttonWidth: minWidth }) => (
        <div
          style={{ minWidth }}
          className="bg-white rounded shadow border select-none origin-top animate-in zoom-in-0 p-6"
        >
          <div className=" flex items-center px-4 font-bold text-xl">{dict.common.userRoles[user.role]}</div>
          <div className="flex items-center justify-center px-4">
            {user.firstName} {user.lastName}
          </div>
          <div className="flex items-center justify-center pt-8">
            <Button
              variant="contained"
              color="primary"
              className="w-full"
              onClick={() => {
                navigate("/");
                logout();
                close();
              }}
            >
              {dict.header.logout}
            </Button>
          </div>
        </div>
      )}
      Button={() => (
        <IconButton>
          <LogoutIcon className="text-black" aria-hidden="false" />{" "}
        </IconButton>
      )}
    />
  );
};

export default UserButton;
