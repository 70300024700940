import TagBase from "components/tag-base";
import { useDictionary } from "hooks/use-dictionary";
import { Fragment, FunctionComponent } from "react";
import { Event, EventStatus } from "types/event";
import { DevelopmentProgram } from "types/program";
import dayjs from "dayjs";
import MissingParticipantsTag from "pages/organizer-admin/events/components/missing-participants-tag";
import { useAuth } from "providers/auth";
import { UserRole } from "types/user";
import { GenericStatusTag } from "components/generic-status-tag";

interface DevelopmentEventTagsProps {
  program: DevelopmentProgram;
  event: Event;
}

const statusMapping: {
  [key: string]: "primary" | "error" | "neutral" | "warning";
} = {
  FINISHED: "primary",
  CANCELED: "error",
  CREATED: "warning",
  FINISHED_WITHOUT_SPECIALISTS: "primary",
};

const DevelopmentEventTags: FunctionComponent<DevelopmentEventTagsProps> = ({ program, event }) => {
  const dict = useDictionary();
  const { user } = useAuth();

  return (
    <Fragment>
      {event.status !== EventStatus.CREATED && user && (
        <div className="mr-2 mb-2">
          <GenericStatusTag type={statusMapping[event.status]} text={dict.common.eventStatus[event.status]} />
        </div>
      )}
      {(user?.role === UserRole.ROLE_ORGANIZER_ADMIN || user?.role === UserRole.ROLE_ORGANIZER_EMPLOYEE) && (
        <div className="mr-2 mb-2">
          {dayjs(event.endDate).isBefore(new Date()) && !event.specialistUsers.length && <MissingParticipantsTag />}
        </div>
      )}
      {program.developmentCompetencyGroupClassifiers.map((x) => (
        <TagBase key={x.classifier.id} className="mr-2 mb-2">
          {x.classifier.valueName}
        </TagBase>
      ))}
      {program.programFormClassifier && (
        <TagBase className="mr-2 mb-2">{program.programFormClassifier.valueName}</TagBase>
      )}
      {event.international && <TagBase className="mr-2 mb-2">{dict.organizerAdmin.events.internationalEvent}</TagBase>}
    </Fragment>
  );
};

export default DevelopmentEventTags;
