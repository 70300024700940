import { useDictionary } from "hooks/use-dictionary";
import FiltersAlertBase from "components/filters-alert";
import { PublicEventsFilters } from "types/event";
import dayjs from "dayjs";
import { usePublicOrganizerInstitutions } from "hooks/api/use-public-organizer-institutions";
import { useMemo } from "react";

interface FiltersAlertProps {
  filters: PublicEventsFilters;
}

interface InstitutionMap {
  [key: string]: string;
}

const FiltersAlert = ({ filters }: FiltersAlertProps) => {
  const dict = useDictionary();
  const { items: institutions } = usePublicOrganizerInstitutions();

  const institutionMap = useMemo(() => {
    return institutions.reduce((acc: InstitutionMap, institution) => {
      acc[institution.id] = institution.name;
      return acc;
    }, {} as InstitutionMap);
  }, [institutions]);

  return (
    <FiltersAlertBase
      formatters={{
        name: (val) => `${dict.organizerAdmin.events.name} (${val})`,
        startDate: (val) => `${dict.organizerAdmin.events.eventStartDate} (${dayjs(val).format("YYYY-MM-DD")})`,
        endDate: (val) => `${dict.organizerAdmin.events.eventEndDate} (${dayjs(val).format("YYYY-MM-DD")})`,
        organizerInstitution: (val) => {
          if (val !== null) {
            return `${dict.organizerAdmin.events.organizer} (${institutionMap[val] || val})`;
          }
          return "";
        },
        developmentCompetencyGroupClassifier: (val) => `${dict.organizerAdmin.events.qualification}`,
        programType: (val) => {
          if (val !== null) {
            return `${dict.organizerAdmin.events.type} (${dict.common.programType[val]})`;
          }
          return "";
        },
        eventHours: (val) => `${dict.organizerAdmin.events.duration} (${val})`,
        review: (val) => `${dict.organizerAdmin.events.minimalReview} (${val})`,
        price: (val) => `${dict.organizerAdmin.events.maxPrice} (${val})`,
      }}
      filters={filters}
    />
  );
};

export default FiltersAlert;
