import { useMemo } from "react";
import dayjs from "dayjs";

const useFormattedDate = (date?: string | null) => {
  return useMemo(() => {
    if (!date) {
      return "-";
    }
    return dayjs(date).format("YYYY-MM-DD");
  }, [date]);
};

export default useFormattedDate;
