import { ClassifierValue } from "types/classifier";
import { User } from "types/user";
import { ProgramEvaluationStatus } from "./status";
import { OrganizerInstitution } from "types/institution/organizer";
import { ReviewRating } from "types/review";

export interface FormMandatoryTopicClassifier {
  mandatoryTopicClassifierId: string;
  mandatoryTopicDurationHours: number;
}

export interface FormMandatoryTopicClassifierDraft {
  mandatoryTopicClassifierId: ClassifierValue;
  mandatoryTopicDurationHours: number;
}

export interface MandatoryTopicClassifier {
  id: number;
  classifier: ClassifierValue;
  duration: number;
}

export interface ProgramInitialEvaluation {
  status: NonNullable<ProgramDecisionStatus>;
  files: File[];
  comment: string;
  evaluators?: (string | undefined)[] | undefined;
  ministerOrderNumber?: string;
  ministerOrderDate?: string;
  programDecisionReasonClassifiers?: string[];
}
export interface FormProgramInitialEvaluation {
  files: File[];
  comment: string;
  needsFix: boolean;
  needsEvaluators: boolean;
  programDecisionReasonClassifiers?: string[];
}

export interface ProgramReturnEvaluation {
  status: NonNullable<ProgramDecisionStatus>;
  files: File[];
  comment?: string;
  evaluators?: string[];
  ministerOrderNumber?: string;
  ministerOrderDate?: string;
  programDecisionReasonClassifiers: string[];
}
export interface FormProgramReturnEvaluation {
  files: File[];
  comment?: string | undefined;
  needsFix: boolean;
  needsEvaluators: boolean;
  programDecisionReasonClassifiers: string[];
}

export interface FormProgramEvaluatorEvaluation
  extends Omit<ProgramInitialEvaluation, "needsFix" | "needsEvaluators"> {}

export interface FormProgramBase {
  typeClassifierId?: string;
  name?: string;
  descriptionSummary?: string;
  dateFrom?: string | undefined | null;
  dateTo?: string | undefined | null;
  listenerCountFrom?: number;
  listenerCountTo?: number;
  mandatoryTopics?: FormMandatoryTopicClassifier[];
  targetedProgramClassifierIds?: string[];
  isCreating?: boolean;
}

export interface ProgramBase {
  id: number;
  creator: User;
  name: string;
  confirmationDate: string | null;
  confirmationDecisionNumber: number | null;
  ministerOrderDate: string;
  ministerOrderNumber: string;
  createdAt: string | null;
  currentDecision?: ProgramEvaluationStatus;
  decisions?: ProgramEvaluationStatus[];
  unenrollmentDate: string | null;
  updatedAt: string | null;
  description: string;
  dateFrom: string | null;
  dateTo: string | null;
  listenersCountFrom: number;
  listenersCountTo: number;
  identification: string;
  implementationPlace: string;
  mandatoryTopicClassifiers: MandatoryTopicClassifier[];
  typeClassifier: ClassifierValue;
  type: ProgramType;
  status: ProgramStatus;
  targetedAudienceClassifiers: ClassifierValue[] | null;
  targetedProgramClassifiers: ClassifierValue[] | null;
  versions: {
    id: number;
    createdAt: string;
  }[];
  evaluators: User[];
  organizerInstitution?: OrganizerInstitution;
  durationSum: number;
  reviewRating: ReviewRating;
}

export type ProgramsFilters = {
  creator: string | null;
  name: string | null;
  code: string | null;
  type: ProgramType | null;
  status: ProgramStatus | null;
};

export enum ProgramType {
  DEVELOPMENT_TYPE = "DEVELOPMENT_TYPE",
  LEARNING_TYPE = "LEARNING_TYPE",
}

export enum ProgramTypeGroupName {
  DEVELOPMENT_TYPE = "Profesinės kvalifikacijos tobulinimo programa",
  LEARNING_TYPE = "Mokymo programa",
}

export enum ProgramFormGroupName {
  ONE_TIME_EVENT = "vienkartiniai specialistų tobulinimo renginiai",
}

export enum ProgramStatus {
  EVALUATING = "EVALUATING",
  EVALUATED_BY_COMMISSION = "EVALUATED_BY_COMMISSION",
  CONFIRMED = "CONFIRMED",
  CANCELED = "CANCELED",
  CHANGES_REQUIRED = "CHANGES_REQUIRED",
  DRAFT = "DRAFT",
}

export enum ProgramDecisionStatus {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  DECLINED_MAIN = "DECLINED_MAIN",
  NOT_FOUND = "NOT_FOUND",
}

export interface ProgramParticipant {
  name: string;
  surname: string;
  personalCode: string;
}
