import NotificationsIcon from "@mui/icons-material/Notifications";
import Tooltip from "components/tooltip";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { Notification } from "types/notification";
import { useDictionary } from "hooks/use-dictionary";
import useNotifications from "hooks/api/common/notifications/use-notifications";
import { useCallback, useEffect, useState } from "react";
import { User } from "types/user";
import IconButton from "components/icon-button";

type NotificationsButtonProps = {
  user: User;
};

const NotificationPopupContent = ({
  data,
  onClose,
  dict,
}: {
  data: Notification[];
  onClose: () => void;
  dict: ReturnType<typeof useDictionary>;
}) => {
  const navigate = useNavigate();
  const handleNavigate = useCallback(() => {
    onClose();
    navigate(ROUTES.user + ROUTES.common.notifications);
  }, [navigate, onClose]);

  const [liveMessage, setLiveMessage] = useState("");

  useEffect(() => {
    if (data.length === 0) {
      setLiveMessage(dict.common.notifications.noNotificationsForYou);
    } else {
      setLiveMessage(`${data.length} ${dict.common.notifications.newNotifications}`);
    }
  }, [data, dict]);

  if (data.length === 0) {
    return (
      <div className="bg-white rounded shadow border select-none origin-top animate-in zoom-in-0 w-[370px]">
        <div className="hover:bg-theme-gray100 py-2 flex items-center px-4 cursor-pointer">
          {dict.common.notifications.noNotificationsForYou}
        </div>
        <div aria-live="polite" style={{ position: "absolute", left: "-9999px" }}>
          {liveMessage}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded shadow border select-none origin-top animate-in zoom-in-0 w-[370px]">
      {data.map((item) => (
        <div
          key={item.id}
          className="hover:bg-theme-gray100 p-3 border-b cursor-pointer text-sm [&_a]:text-theme-primary [&_a]:font-medium break-words"
          onClick={handleNavigate}
          dangerouslySetInnerHTML={{ __html: item.message }}
        />
      ))}
      <div className="flex justify-end text-sm px-3 py-2">
        <div className="text-theme-primary font-medium cursor-pointer" onClick={handleNavigate}>
          {dict.common.notifications.viewAll}
        </div>
      </div>
      <div aria-live="polite" style={{ position: "absolute", left: "-9999px" }}>
        {liveMessage}
      </div>
    </div>
  );
};

const NotificationsButton = ({ user }: NotificationsButtonProps) => {
  const { items: data } = useNotifications(5, !!user);
  const dict = useDictionary();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Tooltip
      placement="bottom"
      Popup={({ close }) => (
        <NotificationPopupContent
          data={data}
          onClose={() => {
            close();
            setIsOpen(false);
          }}
          dict={dict}
        />
      )}
      Button={() => (
        <IconButton>
          <NotificationsIcon
            aria-hidden="false"
            aria-label={dict.common.notifications.button}
            aria-expanded={isOpen}
            onClick={handleToggle}
            className="text-black"
          />
        </IconButton>
      )}
    />
  );
};

export default NotificationsButton;
