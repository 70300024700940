import { FileRecord } from "./program/file-record";
import { User, UserRole } from "./user";

export enum SurveyQuestionTypeEnum {
  QUESTION_TYPE_OPEN = "QUESTION_TYPE_OPEN",
  QUESTION_TYPE_SINGLE_CHOICE = "QUESTION_TYPE_SINGLE_CHOICE",
  QUESTION_TYPE_MULTI_CHOICE = "QUESTION_TYPE_MULTI_CHOICE",
  QUESTION_TYPE_RATING = "QUESTION_TYPE_RATING",
  QUESTION_TYPE_SINGLE_CHOICE_OPEN = "QUESTION_TYPE_SINGLE_CHOICE_OPEN",
  QUESTION_TYPE_MULTI_CHOICE_OPEN = "QUESTION_TYPE_MULTI_CHOICE_OPEN",
  QUESTION_TYPE_RATING_OPEN = "QUESTION_TYPE_RATING_OPEN",
}

export enum SurveyStatusEnum {
  PENDING = "PENDING",
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT",
  CLOSED = "CLOSED",
}

export enum SurveyAnswerRecordStatusEnum {
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
}

export enum SurveyRespondentStatusEnum {
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
  CLOSED = "CLOSED",
  NEW = "NEW",
}

type RespondentLicenseType = {
  id: number;
  groupName: string;
  valueName: string;
};

export type SurveysFilters = {
  q: string | null;
  licenceGroupIds: string[];
  userGroups: string[];
  publishYear?: number;
};

export interface SurveyVersion {
  id: number;
  data: Survey;
  createdAt: string;
}

export interface Survey {
  id: number;
  name: string;
  respondentLicenseTypes?: RespondentLicenseType[];
  respondentUserGroups?: UserRole[];
  questions: SurveyQuestion[];
  publishDate: string | null;
  closeDate: string | null;
  status: SurveyStatusEnum;
  respondentStatus: SurveyRespondentStatusEnum;
  minimumRespondentCount: number | null;
  reminderIntervalDays: number | null;
}

export interface SurveyResult {
  closeDate: string;
  creator: User;
  minimumRespondentCount: number;
  name: string;
  publishDate: string;
  questions: SurveyQuestion[];
  reminderIntervalDays: number;
  respondentLicenseTypes: RespondentLicenseType[];
  respondentStatus: SurveyRespondentStatusEnum;
  respondentUserGroups: UserRole[];
  status: SurveyStatusEnum;
}

export interface FormSurvey {
  name: string;
  respondentLicenseTypes?: string[];
  respondentUserGroups?: string[];
  questions: FormSurveyQuestion[];
  publishDate: string | null;
  closeDate: string | null;
  minimumRespondentCount: number | null;
  reminderIntervalDays: number | null;
}

export interface SendFormSurvey {
  name: string;
  respondentLicenseTypes?: string[];
  respondentUserGroups?: string[];
  questions?: SendFormSurveyQuestion[];
  publishDate: string | null;
  closeDate: string | null;
  minimumRespondentCount: number | null;
  reminderIntervalDays: number | null;
}

export interface SurveyQuestion {
  id: number;
  name: string;
  required: boolean;
  files?: (File | FileRecord)[];
  questionType: SurveyQuestionTypeEnum;
  questionOptions?: FormSurveyQuestionOption[];
  statsTextValues?: string[];
}

export interface FormSurveyQuestion {
  id?: number;
  name: string;
  required: boolean;
  files?: (File | FileRecord)[];
  questionType: SurveyQuestionTypeEnum;
  questionOptions?: FormSurveyQuestionOption[];
  statsTextValues?: string[];
}

export interface SendFormSurveyQuestion {
  id?: number;
  name: string;
  required: boolean;
  files?: number[];
  questionType: SurveyQuestionTypeEnum;
  questionOptions?: FormSurveyQuestionOption[];
  statsTextValues?: string[];
}

export interface SurveyAnswerRecord {
  id: number;
  survey: Survey["id"];
  surveyQuestionAnswers: SurveyQuestionAnswer[];
  status: SurveyAnswerRecordStatusEnum;
}

export interface FormSurveyQuestionOption {
  id?: number;
  key?: string;
  value: string;
  stats?: number;
  statsTextValues?: string[];
}

export interface FormSurveyResponse {
  surveyQuestionAnswers: FormSurveyQuestionAnswer[];
  status: SurveyRespondentStatusEnum;
}

export interface SendFormSurveyResponse {
  surveyQuestionAnswers: SendFormSurveyQuestionAnswer[];
  status: SurveyRespondentStatusEnum;
}

export interface SurveyQuestionAnswer {
  id: number;
  question: { id: number };
  selectedOptions: {
    selectedOption: { id: number };
    text?: string | null;
    textRequired: boolean;
  }[];
  text: string | null;
}

export interface FormSurveyQuestionAnswer {
  question: number;
  questionType: SurveyQuestionTypeEnum;
  required: boolean;
  text?: string;
  selectedOptions?: FormSurveySelectedOption[];
}

export type FormSurveySelectedOption = {
  selectedOption?: number | undefined;
  text?: string;
  textRequired?: boolean;
};

export interface SendFormSurveyQuestionAnswer {
  question: number;
  text?: string;
  selectedOptions?: FormSurveySelectedOption[];
}

export function isRatingValue(data: FormSurveyQuestionOption) {
  return data.key !== undefined && data.key !== null;
}

export function isOpenQuestionValue(data: FormSurveyQuestionOption) {
  return data.value === undefined || data.value === null;
}

export type RatingValue = {
  id: number;
  key: string;
  value: string;
  textRequired: boolean;
};

export type SingleOrMultiValue = {
  value: number;
  label: string | null;
  textRequired: boolean;
};

export interface FormSurveyCriteria {
  publishDate: string;
  closeDate: string;
  minimumRespondentCount: number | null;
  reminderIntervalDays: number;
}
