import Star from "@mui/icons-material/Star";
import StarHalf from "@mui/icons-material/StarHalf";
import StarBorder from "@mui/icons-material/StarBorder";
import { ComponentProps } from "react";
import { ReviewRating } from "types/review";

interface StarsRatingProps extends ComponentProps<"div"> {
  reviews: ReviewRating;
}

const StarsRating = ({ reviews }: StarsRatingProps) => {
  const size = "!w-5 !h-5";
  const formattedRating = parseFloat(reviews.rating.toFixed(1));
  return (
    <div className="flex text-theme-star items-center leading-none text-sm">
      <div className="mr-1.5">{formattedRating}</div>
      {new Array(Math.floor(formattedRating)).fill(0).map(() => (
        <Star className={size} />
      ))}
      {Math.floor(formattedRating) < formattedRating && <StarHalf className={size} />}
      {5 - formattedRating > 1 && <StarBorder className={size} />}
      <div className="text-theme-gray300 ml-1.5">({reviews.reviewCount})</div>
    </div>
  );
};

export default StarsRating;
