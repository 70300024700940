import { useDictionary } from "hooks/use-dictionary";
import { FileRecord } from "types/program/file-record";
import FileViewButton from "./file-view-button";

export type SurveyFormFileListProps = {
  files: FileRecord[];
};

export const SurveyFormFileList = ({ files }: SurveyFormFileListProps) => {
  const dict = useDictionary();

  if (files && files.length > 0) {
    return (
      <div>
        <div className="font-bold mb-2">
          {dict.programs.programDescriptionFiles}
        </div>
        <div className="space-y-3">
          {files.map((file, index) => (
            <>
              <FileViewButton file={file} key={index} />
            </>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="font-medium text-gray-700">
      {dict.programs.noProgramDescriptionFiles}
    </div>
  );
};
