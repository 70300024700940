import classNames from "classnames";
import { ComponentProps, FunctionComponent } from "react";

interface TableBaseProps extends ComponentProps<"table"> {}

const TableBase: FunctionComponent<TableBaseProps> = ({ className, ...props }) => {
  return (
    <table
      {...props}
      className={classNames(
        className,
        "w-full border text-left [&_thead_tr]:bg-theme-background text-sm [&_tbody_tr]:bg-white [&_thead_tr]:border-b [&_td]:px-4 [&_td]:py-3 [&_th]:py-3 [&_th]:px-4  [&_th]:font-medium [&_tbody_tr]:border-b"
      )}
    ></table>
  );
};

export default TableBase;
