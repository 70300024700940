import { useEffect, useMemo } from "react";
import { FileRecord } from "types/program/file-record";
import { EvaluationQuestionnaire, FormQuestionnaireQuestionOption } from "types/questionnaire";
import { isRatingValue, RatingValue, SingleOrMultiValue, SurveyQuestionTypeEnum } from "types/survey";
import { useDictionary } from "hooks/use-dictionary";
import { externalEvaluationResponseFormResolver } from "./external-evaluation-response-form-resolver";
import { useForm } from "react-hook-form";
import { useScrollToError } from "hooks/use-scroll-to-error";
import FormGrid from "pages/organizer-admin/program-create/program-form/components/form-grid";
import FormTextField from "components/form/form-text-field";
import SurveyRadioGroup from "components/survey-radio-group";
import SurveyCheckboxGroup from "components/survey-checkbox-group";
import QuestionnaireRatingGroup from "components/questionnaire-rating-group";
import { Button } from "@mui/material";
import * as yup from "yup";
import { SurveyFormFileList } from "components/survey-answer-file-list";

const { schema, resolver } = externalEvaluationResponseFormResolver();

export type FormQuestionnaireResponse = yup.InferType<typeof schema>;

interface ExternalEvaluationResponseFormProps {
  questionnaireToRespond: EvaluationQuestionnaire;
  isPending: boolean;
  onSubmit: (data: FormQuestionnaireResponse) => void;
}

const ExternalEvaluationResponseForm = ({
  questionnaireToRespond,
  isPending,
  onSubmit,
}: ExternalEvaluationResponseFormProps) => {
  const dict = useDictionary();

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = useForm<FormQuestionnaireResponse>({
    resolver,
  });

  const disabledClick = isPending || isSubmitting;

  useScrollToError(isSubmitting);

  const formFields = useMemo(
    () =>
      questionnaireToRespond.questionnaireQuestions.map((question) => ({
        id: question.id,
        text: question.text,
        required: question.required,
        type: question.type,
        files: question.files as FileRecord[],
        options: (question.questionnaireQuestionOptions as FormQuestionnaireQuestionOption[]).map((option) => {
          if (isRatingValue(option)) {
            return {
              id: option.id as number,
              key: option.key as string,
              value: option.value,
              textRequired: option.value === null,
            };
          } else {
            return {
              value: option.id as number,
              label: option.value,
              textRequired: option.value === null,
            };
          }
        }),
      })),
    [questionnaireToRespond.questionnaireQuestions]
  );

  useEffect(() => {
    const formFieldAnswers = formFields.map(({ id, type, required }) => ({
      questionnaireQuestion: id,
      type,
      required,
    }));
    if (questionnaireToRespond) {
      reset({
        questionnaireQuestionUserRecords: formFieldAnswers,
      });
    }
  }, [formFields, questionnaireToRespond, reset]);

  return (
    <>
      <div className="@container overflow-hidden space-y-6">
        {formFields.map((field, index) => (
          <div key={index} className="border rounded bg-white">
            <div className="flex justify-between border-b p-3">
              <div className="font-bold text-2xl">{index + 1}</div>
              {field.required && <div className="font-bold text-end">{dict.surveys.mandatory}</div>}
            </div>
            <FormGrid className="p-3">
              <div>{field.text}</div>
            </FormGrid>
            <FormGrid className="p-3">
              <SurveyFormFileList files={field.files} />
            </FormGrid>
            <FormGrid className="p-3 !grid-cols-1">
              {field.type === SurveyQuestionTypeEnum.QUESTION_TYPE_OPEN && (
                <FormTextField
                  control={control}
                  name={`questionnaireQuestionUserRecords.${index}.textAnswer`}
                  label={dict.surveys.freeFormAnswer}
                />
              )}
              {(field.type === SurveyQuestionTypeEnum.QUESTION_TYPE_SINGLE_CHOICE ||
                field.type === SurveyQuestionTypeEnum.QUESTION_TYPE_SINGLE_CHOICE_OPEN) &&
                field.options.length > 0 && (
                  <SurveyRadioGroup
                    control={control}
                    name={`questionnaireQuestionUserRecords.${index}.selectedOptions`}
                    label={dict.surveys.singleChoiceAnswer}
                    options={field.options as SingleOrMultiValue[]}
                  />
                )}
              {(field.type === SurveyQuestionTypeEnum.QUESTION_TYPE_MULTI_CHOICE ||
                field.type === SurveyQuestionTypeEnum.QUESTION_TYPE_MULTI_CHOICE_OPEN) &&
                field.options.length > 0 && (
                  <SurveyCheckboxGroup
                    control={control}
                    name={`questionnaireQuestionUserRecords.${index}.selectedOptions`}
                    label={dict.surveys.multiChoiceAnswer}
                    options={field.options as SingleOrMultiValue[]}
                  />
                )}
              {(field.type === SurveyQuestionTypeEnum.QUESTION_TYPE_RATING ||
                field.type === SurveyQuestionTypeEnum.QUESTION_TYPE_RATING_OPEN) &&
                field.options.length > 0 && (
                  <QuestionnaireRatingGroup
                    control={control}
                    answerIndex={index}
                    options={field.options as RatingValue[]}
                  />
                )}
            </FormGrid>
          </div>
        ))}
        <Button
          variant="contained"
          color="primary"
          className="!px-5"
          onClick={handleSubmit(onSubmit)}
          disabled={disabledClick}
        >
          {disabledClick ? dict.common.loading : dict.common.submit}
        </Button>
      </div>
    </>
  );
};

export default ExternalEvaluationResponseForm;
