import classNames from "classnames";
import { ComponentProps, FunctionComponent } from "react";

interface IconButtonProps extends ComponentProps<"div"> {
  size?: string;
}

const IconButton: FunctionComponent<IconButtonProps> = ({ className, children, size, ...props }) => {
  return (
    <div
      {...props}
      className={classNames(
        "rounded-full border hover:bg-theme-gray100 cursor-pointer flex items-center justify-center",
        size,
        className,
        {
          "w-10 h-10": size == null,
        }
      )}
    >
      {children}
    </div>
  );
};

export default IconButton;
