import { ComponentProps, FunctionComponent } from "react";
import { Link as DefaultLink } from "react-router-dom";

interface LinkProps extends ComponentProps<typeof DefaultLink> {
  scroll?: boolean;
}

const Link: FunctionComponent<LinkProps> = ({ onClick, scroll = true, ...props }) => {
  return (
    <DefaultLink
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
        if (scroll) {
          window.scrollTo(0, 0);
        }
      }}
      {...props}
    />
  );
};

export default Link;
